//
// Pagination
// --------------------------------------------------
$pagination-offset: 20px;

.pagination-custom {
  position: relative;

  li {
    display: inline-block;
    line-height: 1;
  }

  li a {
    &, &:active, &:focus {
      color: $pagination-color;
    }

    &:hover {
      color: $pagination-hover-color;
    }
  }

  li:first-child {
    a:before {
      content: '<';
      padding-right: 10px;
    }
  }

  li:last-child {
    a:after {
      content: '>';
      padding-left: 10px;
    }
  }

  li.disabled,
  li.active {
    pointer-events: none;
  }

  li.active {
    &, a {
      color: $pagination-active-color;
    }
  }

  li + li {
    margin-left: $pagination-offset * .75;
  }
}

@media (min-width: $screen-md-min) {
  .pagination-custom {
    li + li {
      margin-left: $pagination-offset;
    }
  }
}