/*
*
* Quotes
* --------------------------------------------------
*/

/**
* Quote components
*/
 .quote-icon {
  margin-left: -.23em;
  font-size: 44px;
  line-height: 1;
  color: $brand-primary;
}

.quote-icon.mdi-format-quote {
  transform: rotate(-180deg);
  margin-left: -0.2em;
}

@media (min-width: $screen-xl) {
  .quote-icon { font-size: 56px; }
}

.quote {
  padding: 20px;
  background: $athens-gray;
  border-radius: 3px;
  border-left: 3px solid $shakespeare;

  img {
    border-radius: 5px;
  }

  q {
    font-style: italic;
  }

  cite {
    font-size: 16px;
    font-weight: 500;
    color: $gray-light;
  }

  .quote-meta {
    margin-top: 9px;

    span {
      display: inline-block;
      margin-left: 12px;
    }
  }

  * + .quote-body { margin-top: 10px; }

}

@media (min-width: $screen-sm-min) {
  .quote {
    padding: 27px 33px;
    cite {
      font-size: 18px;
    }
  }
}

/*
* Default styles
*/
blockquote {
  padding: 0;
  margin: 0;
  font-size: inherit;
  border-left: none;

  q {
    &:before, &:after { content: none; }

    font-style: italic;
  }

  cite {
    font-style: normal;
    font-weight: 700;
  }
}

/**
* Quote
*/
.quote-default {
  img {
    max-width: 60px;
    height: auto;
    border-radius: 50%;
  }

  q { color: $gray; }

  .unit { @include unit-spacing($medias, 10px, 15px); }

  cite {
    font-size: 16px;
    font-weight: 700;
    color: $gray-base;
  }

  * + .quote-meta { margin-top: 5px; }

  @media (min-width: $screen-lg) {
    * + .quote-body { margin-top: 6px; }
    * + .quote-meta { margin-top: 18px; }
  }
}

// quote offsets
* + .quote-default { margin-top: 10px; }

@media (min-width: $screen-lg) {
  * + .quote-default { margin-top: 24px; }
}

// context styles
.context-dark {
  .quote-default { color: $white; }
}

.quote-variant-1 {
  padding: 40px 5px 35px 5px;
  text-align: center;
  background: $white;
  border-radius: 5px;
  border: 1px solid $gray-lighter;
  @include box-shadow(0 0 6px 3px rgba($silver-chalice, .05));

  img {
    border-radius: 50%;
  }

  q {
    font-style: italic;
  }

  cite {
    font-size: 16px;
    font-weight: 700;
    color: $gray-base;
  }

  small {
    &:before {
      display: none;
    }
  }

  .quote-header {
    white-space: nowrap;

    img,
    &:before,
    &:after {
      display: inline-block;
      vertical-align: middle;
    }

    img {
      margin: 0 20px;
    }

    &:before,
    &:after {
      content: '';
      width: 30px;
      margin-left: -.25em;
      border-bottom: 1px solid $gray-lighter;
    }
  }

  .quote-body {
    padding: 6%;
    margin-top: 22px;
    line-height: (21 / 15);
  }

  .quote-meta {
    margin-top: 22px;
    * + p {
      margin-top: 0;
    }
  }
}

@media (min-width: $screen-xs-min) {
  .quote-variant-1 {
    .quote-body {
      padding: 0 10%;
    }

    .quote-header {
      &:before,
      &:after {
        width: 66px;
      }
    }
  }
}


.quote-variant-2 {
  position: relative;
  padding-top: 16px;
  padding-bottom: 23px;
  font-style: italic;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;

  q {
    font-weight: 400;
    color: $gray-dark;
    &:before,
    &:after {
      content: '"';
    }
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    width: 270px;
    height: 1px;
    @include transform(translateX(-50%));
    background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(217,217,217,1) 50%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(217,217,217,1) 50%,rgba(0,0,0,0) 100%);
    background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(217,217,217,1) 50%,rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00000000',GradientType=1 );

  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }
}

@media (min-width: $screen-sm-min) {
  .quote-variant-2 {
    font-size: 24px;
  }
}

@media (min-width: $screen-md-min) {
  .quote-variant-2 {
    font-size: 30px;
    line-height: (48 / 30);
  }
}

