//
// Reset Styles
// --------------------------------------------------
.page a {
  &:focus {
    outline: 0;
  }
}

input, select, textarea {
  outline: 0;
  &::-ms-clear {
    display: none;
  }
}

p {
  margin: 0;
}

dl {
  margin-bottom: 0;
}

dt {
  font-weight: $font-weight-base;
}

address {
  margin: 0;
}

html p a {
  &:hover {
    text-decoration: none;
  }
}
