//
// Search Results
// --------------------------------------------------
// Base styles
// --------------------------------------------------

.rd-search {
  position: relative;
  border: 1px solid $gray-lighter;
  border-radius: 5px;

  label, input {
    font-size: 15px;
  }

  label {
    top: 26px;
    margin-bottom: 0;
  }

  input {
    width: 100%;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 300;
    color: $gray-chateau;
    background: $white;
  }

  input {
    border: 0;
    $placeholder-clr: $gray-chateau;
    $placeholder-opacity: 1;
    &:-moz-placeholder {
      color: $placeholder-clr;
      opacity: $placeholder-opacity;
      transition: 0.3s;
    }

    &::-webkit-input-placeholder {
      color: $placeholder-clr;
      opacity: $placeholder-opacity;
      transition: 0.3s;
    }

    &::-moz-placeholder {
      color: $placeholder-clr;
      opacity: $placeholder-opacity;
      transition: 0.3s;
    }

    &:-ms-input-placeholder {
      color: $placeholder-clr;
      opacity: $placeholder-opacity;
      transition: 0.3s;
    }

    &:focus {
      &:-moz-placeholder {
        opacity: 0.4;
      }

      &::-webkit-input-placeholder {
        opacity: 0.4;
      }

      &::-moz-placeholder {
        opacity: 0.4;
      }

      &:-ms-input-placeholder {
        opacity: 0.4;
      }
    }
  }

  .form-group {
    display: block;
    margin: 0;
    font-size: 0;
    .form-control {
      padding: 16px 48px 16px 17px;
      height: auto;
      font-size: $font-size-base;
      line-height: 1.2;
    }
  }

  .rd-search-submit {
    top: 50%;
    background-color: transparent;
    border: none;
    @include transform(translateY(-50%));
  }
}

.rd-search-submit {
  @include reset-button();
  @include make-toggle(
          $rd-navbar-search-toggle-preset,
          $rd-navbar-min-line-height,
          22px,
          $bright-gray
  );
  position: absolute;
  right: 0;
  transition: color .33s;

  &:hover {
    color: $curious-blue;
  }
}

// Live Search Results
.rd-search-results-live {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  padding: 16px 8px 8px;
  margin: -3px -1px 0;
  border-radius: 0 0 5px 5px;
  border: 1px solid $gray-lighter;
  border-top: 0;
  font-size: 16px;
  line-height: 34px;
  color: $text-color;
  background: $rd-navbar-background;
  text-align: left;
  z-index: 998;
  opacity: 0;
  visibility: hidden;

  .search-quick-result {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    color: $gray-base;
  }

  .search_list {
    background: $rd-navbar-background;
    margin-top: 7px;
    font-size: 16px;
    line-height: 30px;

    li + li {
      margin-top: 15px;
    }

    .search_list li + li:last-child {
      margin-top: 8px;
      border-top: 1px solid $gray-light;
      padding-top: 7px;
    }

    .search_error {
      font-size: 14px;
      line-height: 1.6;
    }
  }

  .search_link {
    color: $gray-darker;

    &:hover {
      color: $brand-primary;
    }
  }

  p {
    margin-top: 0;
    font-size: 14px;
    line-height: 1.6;
  }

  .search_title {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    color: $gray-darker;
  }

  .search_submit {
    display: block;
    text-align: center;
    padding: 6px;
    font-weight: 400;
    font-size: 14px;
    color: $brand-primary;
    text-transform: none;
    border-radius: 5px;

    &:hover {
      color: $white;
      background: $malibu;
    }
  }

  .not-empty ~ & {
    visibility: visible;
    opacity: 1;
  }

  @media (min-width: $screen-lg-min) {
    .search_link {
      p {
        display: block;
      }
    }
  }
}

.search_error {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rd-navbar-fixed-linked {
  .rd-search-results-live {
    display: none;
  }
}

.rd-search-results-live-dark {
  color: $gray-chateau;
  background: $bright-gray;
  border: 1px solid $bright-gray;

  .search-quick-result {
    color: $aqua-haze;
    background: $bright-gray;
  }

  .search_link {
    color: $gray-chateau;

    &:hover {
      color: $curious-blue;
    }
  }

  .search-error,
  .search_list {
    background: transparent;
  }

  .search_submit {
    display: block;
    text-align: center;
    padding: 6px;
    font-weight: 400;
    font-size: 14px;
    color: $cornflower;
    text-transform: none;
    border-radius: 5px;

    &:hover {
      color: $white;
      background: $cornflower;
    }
  }
}

.rd-search-results {
}

.search_list {
  text-align: left;
  padding-left: 0;
  font-size: 14px;
  list-style-type: none;
}

.result-item {
  p {
    margin-top: 3px;
  }
}

.result-item + .result-item { margin-top: 30px; }

.match {
  font-size: 13px;
  line-height: 1.5;
  color: $brand-primary
}

.rd-navbar-search-results {
  .result-item {
    padding: 35px 5px;
    &, * {
      text-align: left;
    }
    & + .result-item {
      border-top: 1px solid $hr-border;
    }

    * + p {
      margin-top: 21px;
    }
  }
}

.not-empty ~ .rd-search-results-live,
.rd-search-results-live.active.cleared {
  opacity: 0;
  visibility: hidden;
}

div.rd-search-results-live.active {
  opacity: 1;
  visibility: visible;
}