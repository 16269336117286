/*
* @subsection   Progress Bar
*/

.progress-bar-wrap{
  max-width: 100%;
  width: 210px;

  @media (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
    max-width: 120px;
  }

  @media (min-width: $screen-sm-min) {
    max-width: 150px;
  }
}

.progress-bar {
  position: relative;
  width: 100%;
  margin-top: 5px;

  .progress-bar__body {
    position: absolute;
    right: 50%;
    width: 100%;
    top: 50%;
    padding: 0;
    margin: 0;
    text-align: center;
    white-space: nowrap;
    font-size: 34px;
    font-weight: 400;
    line-height: 26px;
    color: $gray-darker;

    &:after{
      content: '%';
    }
  }

  .progress-bar__stroke,
  .progress-bar__trail{
    stroke-linejoin: round;
  }
}

.progress-bar-horizontal{
  text-align: left;
  > svg{
    margin: 6px 8px;
    border-radius: 5px;
    height: 4px !important;
  }

  .progress-bar__body{
    width: auto!important;
    top: -5px;
    right: 0;
  }
}

.progress-bar-radial{
  position: relative;
  padding-bottom: 100%;

  > svg{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 5px;
    overflow: hidden;
  }

  .progress-bar__stroke,
  .progress-bar__trail{
    stroke-location: outside;
  }

  .progress-bar__body{
    @include transform(translate(50%, -50%));
  }
}

.progress-bar-default {
  .progress-bar__stroke{
    stroke: $gray;
  }

  .progress-bar__trail{
    stroke: rgba($gray, 0.05);
  }
}

.progress-bar-primary {
  .progress-bar__stroke{
    stroke: $brand-primary;
  }

  .progress-bar__trail{
    stroke: rgba($gray, 0.05);
  }
}

.progress-bar-sky-blue {
  border-radius: 8px;
  background: url('../images/gradient-1.png') repeat-x;
  -webkit-background-size: auto 100%;
  background-size: auto 100%;
  @include box-shadow(none);

  .progress-bar__stroke{
    stroke: $sky-blue;
  }

  .progress-bar__trail{
    stroke: $gray-lighter;
  }
}

.progress-bar-secondary-2 {
  .progress-bar__stroke{
    stroke: $gray-light;
  }

  .progress-bar__trail{
    stroke: rgba($gray, 0.05);
  }
}


.progress-bar-secondary-1 {
  .progress-bar__stroke{
    stroke: $gray-light;
  }

  .progress-bar__trail{
    stroke: rgba($gray, 0.05);
  }
}

.progress-bar-secondary-3 {
  .progress-bar__stroke{
    stroke: $brand-warning;
  }

  .progress-bar__trail{
    stroke: rgba($gray, 0.05);
  }
}

.progress-bar-secondary-4 {
  .progress-bar__stroke{
    stroke: $brand-danger;
  }

  .progress-bar__trail{
    stroke: rgba($gray, 0.05);
  }
}

.no-body {
  .progress-bar__body{
    display: none;
  }
}


