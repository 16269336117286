// Custom button variants
//

@mixin button-variant-custom($clr, $bg, $bd, $clr-hover, $bg-hover, $bd-hover) {
  &,
  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $clr;
    background-color: $bg;
    border-color: $bd;
  }

  &:hover {
    color: $clr-hover;
    background-color: $bg-hover;
    border-color: $bd-hover;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    @include box-shadow(none);

    // TODO Optimize css export
    &:hover {
      color: $clr-hover;
      background-color: $bg-hover;
      border-color: $bd-hover;
    }
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    pointer-events: none;
    opacity: .5;
  }

  .badge {
    color: $bg;
    background-color: $clr;
  }
}

// Reset Button
@mixin reset-button(){
  background: none;
  border: none;
  display: inline-block;
  padding: 0;
  outline: none;
  outline-offset: 0;
  cursor: pointer;
  -webkit-appearance: none;

  &::-moz-focus-inner {
    border: none;
    padding: 0;
  }
}

