//
// Sections
// --------------------------------------------------

// Section Spacing
// -------------------------

$insets: (14px, 25px, 30px, 35px, 40px, 45px, 50px, 55px, 60px, 68px, 75px, 85px, 125px, 152px, 235px);

@include indent-responsive(section, '', (padding-top, padding-bottom), $medias, $insets);
@include indent-responsive(section, top, padding-top, $medias, $insets);
@include indent-responsive(section, bottom, padding-bottom, $medias, $insets);

.frame-header {
	 width: 100%;
	 height: 350px;
	 outline: none;
	 border: 0;

	 @media (min-width: $screen-sm) {
		 height: 500px;
	 }

	 @media (min-width: $screen-lg) {
		 height: 550px;
	 }
 }