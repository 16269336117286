/*
* Page Loader
*/

.page-loader {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	float: left;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	z-index: 9999999;
	background: #fff;
	transition: .3s all ease;
	
	.loader-header {
		text-transform: uppercase;
		letter-spacing: .1em;
		font-weight: 700;
		color: $gray-base;
	}

	.page-loader-body {
		text-align: center;
	}

	&.loaded {
		opacity: 0;
		visibility: hidden;
	}

	&.ending {
		display: none;
	}
	
	* + .loader-header { margin-top: 10px; }
}


.cssload-container {
	text-align: center;
}

.cssload-speeding-wheel {
	display: inline-block;
	width: 50px;
	height: 50px;
	border: 3px solid $brand-primary;
	border-radius: 50%;
	border-left-color: transparent;
	border-bottom-color: transparent;
	animation: cssload-spin .88s infinite linear;
}

@include keyframes (cssload-spin) {
	100% {
		transform: rotate(360deg);
	}
}