/*
* Gallery items
*/
// Rotate fade
.gallery-block-item {
  display: block;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: .25s;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: block;
    background: rgba($brand-primary, 0.4);
    transition: inherit;
    pointer-events: none;
  }

  &:after {
    content: "+";
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%) rotate(90deg);
    font-size: 3em;
    color: $white;
    transition: inherit;
    will-change: transform;
  }

  > img {
    width: 100%;
  }

  .desktop & {
    &:before { background: rgba($brand-primary, 0); }

    &:after {
      color: transparent;
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &:hover {
      &:before { background: rgba($brand-primary, 0.4); }
      &:after {
        color: $white;
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }
  }
}

/* Gallery ablum item */
.gallery-block-item-1 {
  position: relative;
  overflow: hidden;
  background: $gray-base;
  cursor: pointer;

  .caption {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px;
    color: $white;

    * { color: $white; }
  }

  .heading,
  .exeption { max-width: 100%; }
  .divider-1:before { border-color: $white; }
  .heading { font-weight: 700; }
  .exeption { font-size: 14px; }

  img {
    opacity: 0.5;
    will-change: transform;
    width: 100%;
  }

  .zoom-item {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 50px;
    height: 50px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 160%;
      background: $brand-primary;
      transform: rotate(45deg);
      transform-origin: left bottom;
    }

    &:after {
      content: "\f504";
      position: relative;
      z-index: 1;
      font-family: "Material Design Icons";
      font-size: 30px;
      line-height: 1;
      color: $white;
    }
  }

  * + .divider { margin-top: 10px; }
  * + .exeption { margin-top: 10px; }

  @media (min-width: $screen-lg) {
    .caption { padding: 15px 14%; }

    .zoom-item {
      width: 80px;
      height: 100px;

      &:after {
        padding: 5px;
        font-size: 40px;
      }
    }
  }

  @media (min-width: $screen-xl) {
    .exeption {
      font-size: $font-size-base;
    }
  }
}

.desktop .gallery-block-item-1 {
  .caption {
    opacity: 0;
    transition: .5s .2s;
  }

  img {
    transform: scale(1, 1);
    opacity: 1;
    transition: 1s;
  }

  .zoom-item {
    transform: translateY(100%);
    transition: .3s .2s;
  }

  &:hover {
    img {
      transform: scale(1.05, 1.05);
      opacity: 0.5;
    }

    .caption { opacity: 1; }
    .zoom-item { transform: translateY(0); }
  }
}