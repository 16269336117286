//
// Comment utilities
// --------------------------------------------------
.comment {
  figure {
    &, img {
      border-radius: 50%;
      max-width: 71px;
    }
  }

  time {
    font-size: 12px;
    line-height: 1;
    color: $gray-light;
  }
  .list-icon-meta {
    @include display-flex;
    @include flex-direction(row);
    @include flex-wrap(nowrap);
    @include align-items(center);

    li + li {
      margin-left: 3px;
    }

    li {
      font-size: 12px;
      line-height: 1;
      font-weight: 400;
    }
  }
}

.comment-list {
  @media (min-width: $screen-sm-min) {
    padding-right: 60px;
  }

  @media (min-width: $screen-md-min) {
    padding-right: 30px;
  }
  @media (min-width: $screen-lg-min) {
    padding-right: 100px;
  }
}

.comment-group-reply {
  padding-left: 12%;
}

.user {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: $gray-base;
}

// Elements
.comment-body {
  padding: 17px 22px;
  border: 1px solid $alto;
  border-radius: 7px;
}

.comment-body-header {
  @include display-flex;
  @include flex-direction(row);
  @include align-items(flex-start);
  @include justify-content(space-between);

  @media (min-width: $screen-sm-min) {
    @include align-items(center);
  }

  > * {
    margin-top: 0;
  }

  a:hover {
    color: $curious-blue;
    .icon {
      color: $curious-blue;
    }
  }
}

.comment-meta {
  > * + * {
    margin-top: 5px;
  }
  @media (min-width: $screen-sm-min) {
    @include display-flex;
    @include flex-direction(row);
    @include flex-wrap(nowrap);
    @include align-items(center);

    > * {
      margin-top: 0;
      & + * {
        margin-left: 13px;
      }
    }
  }
}

.comment-body-text {
  margin-top: 10px;
}

.comment-time {

  > * { vertical-align: middle; }

  time {
    margin-right: 5px;
  }
}

// Offsets
.comment + * {
  margin-top: 21px;
}

* + .comment-group {
  margin-top: 30px;
}

@media (min-width: $screen-sm-min) {
  .comment {
    > .unit > .unit-left {
      margin-top: 16px;
    }
  }
  .comment-body {
  }

}

@media (min-width: $screen-md-min) {
  * + .post-comment {
    margin-top: 80px;
  }
}