//
// Offsets
// --------------------------------------------------

// Insets
// -------------------------

$insets: (0, 10px, 15px, 25px, 30px, 50px, 70px);

@include indent-responsive(inset, left, padding-left, $medias, $insets);
@include indent-responsive(inset, right, padding-right, $medias, $insets);

// Elements
// -------------------------

// Grid
// -------------------------
html {
  .range-10 { @include responsive-offset(10px); }
  .range-30 { @include responsive-offset(30px); }
  .range-40 { @include responsive-offset(40px); }
  .range-45 { @include responsive-offset(45px); }
  .range-50 { @include responsive-offset(50px); }
  .range-56 { @include responsive-offset(56px); }
  .range-60 { @include responsive-offset(60px); }

  @media (min-width: $screen-md-min) {
    .range-md-60 { @include responsive-offset-media(60px); }
  }
}

// Sections
// -------------------------

// Responsive
// -------------------------

$offsets: (0, 5px, 10px, 15px, 19px, 22px, 25px, 30px, 34px, 38px, 41px, 45px, 50px, 56px, 60px);

html .page {
  @include indent-responsive(offset, top, margin-top, $medias, $offsets);
}
