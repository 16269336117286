/* 
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  font: 400 40px/80px 'FontAwesome';
  cursor: pointer;
  z-index: 1;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease;
  &:before {
    content: '\f144';
  }
}

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}

/*
 * Owl Navigation
 */

.owl-nav {
  &.disabled { display: none; }
}

.owl-prev, .owl-next {
  position: absolute;
  top: 50%;
  @include transform(translateY(-50%));
  font-family: 'arrows';
  font-size: 48px;
  transition: .33s all ease;
  color: $nobel;
  cursor: pointer;

  &:hover {
    color: $curious-blue;
  }
}

.owl-prev {
  left: 0;
  &:before {
    content: '\e009';
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    left: -10px;
  }

  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    left: -19px;
  }
}

.owl-next {
  right: 0;
  &:before {
    content: '\e008';
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    right: -10px;
  }

  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    right: -19px;
  }
}

/*
 * Owl Pagination
 */

.owl-dots {
  margin-top: 10px;
  text-align: center;

  &.disabled { display: none; }
}

.owl-dot {
  position: relative;
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-left: 10px;
  text-align: center;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px $gray-lighter;
  transition: box-shadow 0.3s ease;

  &:hover,
  &:focus,
  &.active {
    box-shadow: inset 0 0 0 2px $curious-blue;
  }
}

// Additional
.item {
  padding-bottom: 1px;
  padding-top: 1px;
}
.item-inset {
  padding-bottom: 10px;

  @media (min-width: $screen-sm-min) {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

.owl-spacing-1 {
  @media (min-width: $screen-sm-min) {
    padding: 0 25px;
  }
  @media (min-width: $screen-lg-min) {
    padding: 0 55px;
  }
}

.owl-carousel-centered {
  .item {
    text-align: center;
  }
}

.owl-carousel-middle {
  .owl-stage {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .item {
    text-align: center;
  }
}

.owl-carousel-links {
  .owl-stage {
    display: flex;
    flex-direction: row;
  }
  .owl-item {
    padding: 50px 15px;

    &:nth-child(odd) { background: $aqua-haze; }
    &:nth-child(even) { background: $mercury; }
  }

  .item { text-align: center; }
  .owl-prev { left: 10px; }
  .owl-next { right: 10px; }
}

.owl-carousel-custom {
  .owl-nav {
    display: none !important;

    @media (min-width: $screen-sm-min) {
      display: block !important;
    }
  }
}