//
// Post utilities
// --------------------------------------------------

// Post
.post {
  &-heading {
    margin-top: 12px;
    #{headings()} {
      color: $gray-base;
    }
  }
  &-meta {
    margin-top: 12px;
  }

  &-body {
    margin-top: 18px;
    line-height: (26 / 15);
  }
}

.post-footer {
  margin-top: 20px;
  @include display-flex;
  @include flex-direction(row);
  @include align-items(center);
  @include justify-content(space-between);

  > * {
    margin-top: 0;
  }
}

@media (min-width: $screen-sm-min) {
  .post {
    .post-heading {
      margin-top: 22px;
    }

    .post-meta {
      margin-top: 8px;
    }

    .post-footer {
      margin-top: 40px;
    }
  }
}

.post + .post {
  margin-top: 58px;
}

// Post info
.post-info {
  &-body {
    @extend .text-style-1;
    color: $gray-base;
  }
}

.post-info-footer {
  margin-top: 18px;
}

@media (min-width: $screen-sm-min) {
  .post-info-body {
    padding-right: 70px;
  }

  .post-info-footer {
    margin: 51px 40px 0 -10px;
  }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .post-info-footer {
    margin: 51px 0 0 -20px;
  }
}

@media (min-width: $screen-lg-min) {
  .post-info-footer {
    margin-right: 82px;
  }
}

// Post related
.post-related {
  max-width: 270px;
  text-align: left;

  figure {
    &, img {
      border-radius: 50%;
    }
  }

  time {
    font-size: 13px;
    font-weight: 400;
    color: $gray-light;
  }

  #{headings()} {
    line-height: 1.21;
    font-weight: 400;
  }

  .post-related-meta {
    margin-top: -5px;

    > * { vertical-align: middle; }
  }

  .post-related-body {
    margin-top: 4px;
  }
}

.post-related + .post-related {
  margin-top: 23px;
}

@media (min-width: $screen-sm-min) {
  .post-related {
    margin-left: 0;
  }
}

// Post single
.post-single {
  .post-body {
    margin-top: 19px;
  }
}

// Post preview
.post-preview {
  max-width: 270px;
  text-align: left;
  &-image {
    &,
    img {
      border-radius: 17px;
    }
  }

  &-heading {
    font-size: 14px;
    font-weight: 500;
    line-height: (21 / 14);
    color: $gray-base;

    a {
      display: inline;
      &, &:active, &:focus {
        color: $gray-base;
      }

      &:hover {
        color: $curious-blue;
      }
    }
  }

  &-meta {
    margin-top: 7px;
    font-size: 12px;
    font-weight: 300;
    white-space: nowrap;
    color: $gray;

    * + * {
      &:before {
        content: '/';
        display: inline-block;
        width: 8px;
        text-align: center;
      }
    }
  }
}


